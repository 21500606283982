import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/auth/auth-guard.service";
import { translations } from "./config/translations/translations";
import { TeamAssignmentComponent } from "./team-assignment/team-assignment.component";

const routes: Routes = [
  {
    path: "installer-map",
    canActivate: [AuthGuard],
    loadChildren: () => import("./installer-map/installer-map.module").then((m) => m.InstallerMapModule),
  },

  {
    path: "admin",
    canActivate: [AuthGuard],
    component: TeamAssignmentComponent,
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "installer-map",
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "**",
    redirectTo: "",
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
