import { Inject, Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable()
export class InitializerProvider {
  constructor(
    @Inject(BfcTranslationService) private bfcTranslateService: BfcTranslationService,
    @Inject(BfcGoogleAnalyticsService) private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    @Inject(BfcConfigurationService) private bfcConfigurationService: BfcConfigurationService,
  ) {}

  initialize(): void {
    this.bfcGoogleAnalyticsService.initGoogleTagManager(
      this.bfcConfigurationService.configuration.googleTagManagerContainerId,
    );

    const globalNavElements = document.getElementsByTagName(
      "bfe-global-navigation",
    );
    if (globalNavElements.length !== 1) {
      throw new Error("too many or no global navigation element found");
    }
  }
}
