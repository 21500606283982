import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { OrganisationCompetence } from "../generated/installer-map-backend/model/organisationCompetence";

@Injectable()
export class OrganisationCompetenceService {

  constructor(
    private configurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  saveOrganisationCompetences(organisationCompetence): Observable<OrganisationCompetence[]> {
    const url = `${this.configurationService.configuration.apiUrl}/organisationCompetences`;
    return this.httpClient.post<OrganisationCompetence[]>(url, organisationCompetence);
  }

  saveOrganisationCompetenceFachverkaufer(organisationCompetence): Observable<OrganisationCompetence> {
    const url = `${this.configurationService.configuration.apiUrl}/organisationCompetences/fachverkaufer`;
    return this.httpClient.post<OrganisationCompetence>(url, organisationCompetence);
  }

  saveOrganisationCompetenceRecords(organisationCompetence): Observable<OrganisationCompetence> {
    const url = `${this.configurationService.configuration.apiUrl}/organisationCompetences/${organisationCompetence.id}/mapEntries`;
    return this.httpClient.put<OrganisationCompetence>(url, organisationCompetence);
  }

  getOrganisationCompetences(): Observable<OrganisationCompetence[]> {
    const url = `${this.configurationService.configuration.apiUrl}/organisationCompetences`;
    return this.httpClient.get<OrganisationCompetence[]>(url);
  }

}
