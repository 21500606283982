import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CrmResponsibleSubject } from "../generated/installer-map-backend/model/crmResponsibleSubject";

@Injectable()
export class ResponsibleSubjectService {

  constructor(
    private configurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  getResponsibleSubjects(): Observable<CrmResponsibleSubject[]> {
    const url = `${this.configurationService.configuration.apiUrl}/responsibleSubjects`;
    return this.httpClient.get<CrmResponsibleSubject[]>(url);
  }

}
