import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { OrganisationResponsibleSubject } from "../generated/installer-map-backend/model/organisationResponsibleSubject";

@Injectable()
export class OrganisationSubjectService {

  constructor(
    private configurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  saveOrganisationSubject(organisationSubject): Observable<OrganisationResponsibleSubject[]> {
    const url = `${this.configurationService.configuration.apiUrl}/organisationSubjects`;
    return this.httpClient.post<OrganisationResponsibleSubject[]>(url, organisationSubject);
  }

  updateOrganisationSubject(id, organisationSubject): Observable<OrganisationResponsibleSubject> {
    const url = `${this.configurationService.configuration.apiUrl}/organisationSubjects/${id}`;
    return this.httpClient.put<OrganisationResponsibleSubject>(url, organisationSubject);
  }

  getOrganisationSubjects(): Observable<OrganisationResponsibleSubject[]> {
    const url = `${this.configurationService.configuration.apiUrl}/organisationSubjects`;
    return this.httpClient.get<OrganisationResponsibleSubject[]>(url);
  }

  getOrganisationSubjectForOrganisation(id): Observable<OrganisationResponsibleSubject> {
    const url = `${this.configurationService.configuration.apiUrl}/organisationSubjects/${id}`;
    return this.httpClient.get<OrganisationResponsibleSubject>(url);
  }

}
