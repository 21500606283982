import { IStarterAppConfiguration } from "../configuration";

export const T_ENV: IStarterAppConfiguration = {
  apiUrl: "https://api-installer-map-t.bkw.ch",
  competenceSolarId: 300800000,
  competenceWPId: 300800001,
  rolePVId: "9f2a4266-c131-ec11-b6e6-000d3ab6e11b",
  roleWPId: "daf06b72-c131-ec11-b6e6-000d3ab6e11b",
  roleFVId: "a8f2b39a-523a-e711-80d5-005056a7329a",
};
