import { Component, HostBinding, NgZone, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { CommonOrganisationService } from "./core/common-organisation.service";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcConfigurationService } from "@bfl/components/configuration";
import {
  BflOpSelfService,
  OpSelfServiceExtendedCommonOrganisation,
  OpSelfServiceOrganisation,
} from "@bfl/op-self-service";
import { PermissionService } from "./core/permission.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {

  @HostBinding("class")
  classes = "bfc-base";

  callback: (organisation: OpSelfServiceOrganisation) => void = this.onOrganisationChange.bind(this);

  private unsubscribe: Subject<void> = new Subject();

  private readonly baseUrl: string = `${window.location.protocol}//${window.location.host}`;

  roleAdmin: boolean = false;

  currentOrganisation: OpSelfServiceOrganisation;

  readonly navigationTree: any = JSON.stringify({
    subNavigationItems: [
      {
        labelKey: this.translationService.translate("INSTALLER_MAP.NAVIGATION_TITLE"),
        restrictedToOrganisationServiceCodes: ["ROLE_INSTALLER_MAP_ADMIN"],
        url: {
          base: this.baseUrl,
          path: "/installer-map",
        },
      },
      {
        labelKey: this.translationService.translate("INSTALLER_MAP.RESPONSIBLE_SUBJECT_TITLE"),
        restrictedToOrganisationServiceCodes: ["ROLE_INSTALLER_MAP_ADMIN"],
        url: {
          base: this.baseUrl,
          path: "/admin",
        },
      },
    ],
  });

  constructor(private router: Router,
    public commonOrganisationService: CommonOrganisationService,
    private ngZone: NgZone,
    private bfcAuthenticationService: BfcAuthenticationService,
    private configurationService: BfcConfigurationService,
    private translationService:BfcTranslationService,
    private selfService: BflOpSelfService,
    private permissionService: PermissionService,
  ){

  }

  routeRequest(e): void {
    this.router.navigate([e.detail.path]);
  }

  /**
   * Receive continuous ccid updates from the b2b nav
   * @param commonOrganisation
   */
  private onOrganisationChange(organisation: OpSelfServiceOrganisation): void {
    this.ngZone.run(() => {
      this.currentOrganisation = organisation;
      this.selfService.getMeExtendedCommonOrganisations()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((orgs:OpSelfServiceExtendedCommonOrganisation[])=>{
          const foundOrg = orgs?.find(org=>org.commonOrganisationId == organisation.commonOrganisationId);
          const hasAdminRoleAssociatedToOrg = foundOrg.services.some(service=>{
            return service.code == this.configurationService.configuration.adminRole;
          });
          this.permissionService.hasAdminRole.next(hasAdminRoleAssociatedToOrg);
          this.roleAdmin = hasAdminRoleAssociatedToOrg;
          this.commonOrganisationService.selectedOrganisationSubject.next(organisation);
        });

    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
