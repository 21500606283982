import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BflOpSelfService } from "@bfl/op-self-service";
import { map } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private selfService: BflOpSelfService,
    private bfcConfigurationService: BfcConfigurationService,
    private router: Router) {

  }

  canActivate() {
    return this.selfService.getMeExtendedCommonOrganisations().pipe(map(orgs => {
      const readOnlyMode = orgs.some(org=>{
        return org.services.some(service=>service.code == this.bfcConfigurationService.configuration.readOnlyRole);
      });

      if (!readOnlyMode) {
        this.router.navigate(["/unauthorized"]);
        return false;
      } else {
        return true;
      }
    }));
  }
}