import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
} from "@bfl/components/authentication";
import {
  BfcConfigurationModule,
  BFC_CONFIGURATIONS,
} from "@bfl/components/configuration";
import {
  BfcTranslationModule,
  BFC_TRANSLATIONS,
} from "@bfl/components/translation";
import { BfcBasePageLayoutModule } from "@bfl/components/base-page-layout";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { CoreModule } from "./core/core.module";
import { CommonModule } from "@angular/common";
import { BfcLinkModule } from "@bfl/components/link";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcSinglePageLayoutLargeModule } from "@bfl/components/single-page-layout-large";
import { CommonOrganisationService } from "./core/common-organisation.service";
import { BfcNotificationModule, BfcNotificationService } from "@bfl/components/notification";
import { BflOpSelfServiceModule } from "@bfl/op-self-service";
import { InstallerMapErrorHandler } from "./core/error/installer-map-error-handler";
import { TeamAssignmentComponent } from "./team-assignment/team-assignment.component";
import { BfcFormModule } from "@bfl/components/form";
import { BfcCheckboxModule } from "@bfl/components/checkbox";
import { BfcButtonModule } from "@bfl/components/button";
import { BfcRadioModule } from "@bfl/components/radio";
import { BfcSelectModule } from "@bfl/components/select";
import { MatOptionModule } from "@angular/material/core";
import { BfcInputModule } from "@bfl/components/input";
import { BfcFormFieldModule } from "@bfl/components/form-field";

@NgModule({
  declarations: [AppComponent, TeamAssignmentComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    BfcTranslationModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    BfcBasePageLayoutModule,
    BfcLinkModule,
    BfcSinglePageLayoutModule,
    BfcSinglePageLayoutLargeModule,
    BfcNotificationModule,
    BflOpSelfServiceModule,
    BfcFormModule,
    BfcCheckboxModule,
    BfcButtonModule,
    BfcRadioModule,
    CommonModule,
    BfcTranslationModule,
    BfcSelectModule,
    MatOptionModule,
    BfcInputModule,
    BfcButtonModule,
    BfcFormFieldModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: InstallerMapErrorHandler,
    },
    CommonOrganisationService,
    BfcNotificationService,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
