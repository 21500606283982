<div class="bfc-padding-2">
<h2>{{"INSTALLER_MAP.SUBJECT_ASSIGNMENT" | bfcTranslate}}</h2>

<form *ngIf="responsibleSubjectList?.length > 0" [formGroup]="formGroup" class="bfc-margin-2">
    <div class="bfc-form-row">
        <mat-form-field>
            <mat-label>  {{"INSTALLER_MAP.RESPONSIBLE_SUBJECT_TITLE" | bfcTranslate}}</mat-label>
            <mat-select formControlName="responsibleSubject">
                <mat-option *ngFor="let subject of responsibleSubjectList" [value]="subject?.responsibleSubjectId">
                    {{subject?.responsibleSubjectName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>
<button  mat-button class="bfc-button-hero bfc-margin-top-2" (click)="submit()" color="primary" >
    {{'INSTALLER_MAP.SAVE' | bfcTranslate}}
</button>
<button mat-button class="bfc-margin-bottom-2"  color="primary">{{'INSTALLER_MAP.CANCEL' | bfcTranslate}}</button>
</div>
