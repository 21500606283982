import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class CompetenceService {

  constructor(
    private configurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  saveCompetence(competencies): Observable<any[]> {
    const url = `${this.configurationService.configuration.apiUrl}/competences`;
    return this.httpClient.post<any[]>(url, competencies);
  }

  getCompetences(): Observable<any[]> {
    const url = `${this.configurationService.configuration.apiUrl}/competences`;
    return this.httpClient.get<any[]>(url);
  }

}
