import { IStarterAppConfiguration } from "../configuration";

export const GENERAL_ENV: IStarterAppConfiguration = {
  apiUrl: "http://localhost:8080/api",
  googleTagManagerContainerId: "GTM-N4VBJZH",
  successNotificationDuration: 1000,
  adminRole: "ROLE_INSTALLER_MAP_ADMIN",
  readOnlyRole: "ROLE_INSTALLER_MAP",
  portalServicesForeignKey: "portal-services-commonid",
};
