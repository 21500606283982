import { EventEmitter, Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { OpSelfServiceOrganisation } from "@bfl/op-self-service";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class CommonOrganisationService {

  constructor(private configurationService: BfcConfigurationService) {
  }

  // Subscribe for continious organisation updates
  readonly selectedOrganisationSubject: ReplaySubject<OpSelfServiceOrganisation> = new ReplaySubject(1);

  // emit to request a commonOrganisationId Change
  readonly useCommonOrganisationRequest: EventEmitter<OpSelfServiceOrganisation> = new EventEmitter();

  // call this to get a organisation observable that completes
  getSelectedCommonOrganisation(): Observable<OpSelfServiceOrganisation> {
    return new Observable(sub => {
      this.selectedOrganisationSubject.subscribe(organisation => {
        sub.next(organisation);
        sub.complete();
      });
    });
  }

  getPortalServicesCommonId(commonOrganisation): number {
    return parseInt(commonOrganisation.foreignKeys[this.configurationService.configuration.portalServicesForeignKey]);
  }
}
