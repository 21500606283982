import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

@Injectable()
export class PermissionService {

  readonly hasAdminRole: ReplaySubject<boolean> = new ReplaySubject(1);

  getHasAdminRole(): Observable<boolean> {
    return new Observable(role => {
      this.hasAdminRole.subscribe(admin => {
        role.next(admin);
      });
    });
  }

}
