export const DE_TRANSLATIONS = {
  BFC_UNAUTHORIZED: {
    TITLE: "Kein Zugriff",
    CONTENT: "Der Service steht Ihnen leider nicht zur Verfügung.",
  },
  INSTALLER_MAP : {
    NAVIGATION_TITLE: "Gebietskarte",
    SAVE_BUTTON: "Gebiete speichern",
    MARK_ALL: "Alle Gemeinden markieren",
    SUCCESS_MESSAGE: "Die Daten wurden erflogreich geändert!",
    TOTAL: "Total:",
    LOADING_TEXT: "Karte wird geladen...",
    ORGANISATIONS: "Firmen",
    SOLAR: "Solar",
    HEATING: "Heizung",
    SAVE: "Speichern",
    CANCEL: "Abbrechen",
    EDIT_COMPETENCES: "Kompetenzen bearbeiten",
    SERVICES_TITLE: "Gebiete",
    COMPETENCE_TITLE: "Kompetenzen",
    FORBIDDEN_MESSAGE: "Sie sind nicht berechtigt, auf diese Daten zuzugreifen!",
    ERROR_MESSAGE: "Ein Fehler ist aufgetreten!",
    READ_ONLY: "Read-only Modus",
    EDIT_MODE: "Edit mode",
    NO_COMPETENCES: "Diesem Unternehemen sind keine Kompetenzen zugewiesen.",
    PARTNER: "Partner",
    ORTBEZ: "Ort",
    KANTON: "Kanton",
    POSTALCODE: "Postleitzahl",
    RESPONSIBLE_SUBJECT_TITLE: "Fachverantwortliche",
    SUBJECT_ASSIGNMENT: "Zuweisung von Fachverantwortlichen",
  },
};
