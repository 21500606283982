import { Injectable } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import { HttpErrorResponse } from "@angular/common/http";
import { BfcNotification, BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";

@Injectable()
export class InstallerMapErrorHandler {

  constructor(private notificationService: BfcNotificationService,
    private translateService: BfcTranslationService,
  ) {
  }

  handleError(error: Error | HttpErrorResponse) {
    let notification: BfcNotification;
    if (error instanceof HttpErrorResponse) {
      if (error.status == 401 || error.status == 403) {
        notification = {
          type: BfcNotificationType.ERROR,
          message: this.translateService.translate("INSTALLER_MAP.FORBIDDEN_MESSAGE"),
          options: {
            duration: 0,
          },
        };
      } else {
        notification = {
          type: BfcNotificationType.ERROR,
          message: this.translateService.translate("INSTALLER_MAP.ERROR_MESSAGE"),
          options: {
            duration: 0,
          },
        };
      }

    }

    if (notification) {
      this.notificationService.showNotification(notification);
    }
    // Don't swallow the error
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
