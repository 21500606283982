import { Component, OnDestroy, OnInit } from "@angular/core";
import { RoleService } from "../core/role.service";
import { ResponsibleSubjectService } from "../core/responsible-subject.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { OpSelfServiceOrganisation } from "@bfl/op-self-service";
import { CommonOrganisationService } from "../core/common-organisation.service";
import { OrganisationSubjectService } from "../core/organisation-subject.service";
import { CrmRole } from "../generated/installer-map-backend/model/crmRole";
import { OrganisationResponsibleSubject } from "../generated/installer-map-backend/model/organisationResponsibleSubject";
import { CrmResponsibleSubject } from "../generated/installer-map-backend/model/crmResponsibleSubject";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { mergeMap, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-role-assignement",
  templateUrl: "./team-assignment.component.html",
  styleUrls: ["./team-assignment.component.scss"],
})

export class TeamAssignmentComponent implements OnInit, OnDestroy{

  roleList: CrmRole[];

  public formGroup: FormGroup;

  responsibleSubjectList: CrmResponsibleSubject[];

  currentCommonOrganisation: OpSelfServiceOrganisation;

  currentOrganisationSubject: OrganisationResponsibleSubject;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private roleService: RoleService,
    private responsibleSubjectService: ResponsibleSubjectService,
    private bfcNotificationService: BfcNotificationService,
    private formBuilder: FormBuilder,
    private commonOrganisationService: CommonOrganisationService,
    private organisationSubjectService: OrganisationSubjectService,
    private translationService: BfcTranslationService,
    private configurationService: BfcConfigurationService) {
    if (this.roleList && this.roleList.length !== 0) {

    }
  }

  ngOnInit() {
    // When organisationId is changed, the map is redrawn with new data.
    this.commonOrganisationService.selectedOrganisationSubject.pipe(
      mergeMap(
        (commonOrganisation: OpSelfServiceOrganisation) => {
          this.currentCommonOrganisation = commonOrganisation;
          return this.organisationSubjectService.getOrganisationSubjects();
        }),
      takeUntil(this.unsubscribe),
    ).subscribe(data=>{
      if (data) {
        let currentSubject = data?.find(subject=>{
          return subject.commonOrganisationId ==
              this.commonOrganisationService.getPortalServicesCommonId(this.currentCommonOrganisation);
        });
        this.currentOrganisationSubject = currentSubject;
        if (currentSubject) {
          this.formGroup.get("responsibleSubject").setValue(currentSubject?.responsibleSubjectId);
        } else {
          this.formGroup.get("responsibleSubject").setValue(null);
        }
      }
    });
    this.formGroup = this.formBuilder.group({

      responsibleSubject:false,
    });
    this.roleService.getRoles().pipe(
      takeUntil(this.unsubscribe),
    ).subscribe();
    this.responsibleSubjectService.getResponsibleSubjects().subscribe(subjects=> {
      if (subjects?.length > 0) {
        this.responsibleSubjectList = subjects;
      }
    });
  }

  submit() {
    let subscription;
    if (this.currentOrganisationSubject?.id) {
      subscription = this.organisationSubjectService.updateOrganisationSubject(
        this.currentOrganisationSubject?.id,
        {
          id: this.currentOrganisationSubject?.id,
          commonOrganisationId:this.commonOrganisationService.getPortalServicesCommonId(this.currentCommonOrganisation),
          responsibleSubjectId:this.formGroup?.value?.responsibleSubject,
        },
      );
    } else {
      subscription = this.organisationSubjectService.saveOrganisationSubject(
        {
          commonOrganisationId:this.commonOrganisationService.getPortalServicesCommonId(this.currentCommonOrganisation),
          responsibleSubjectId:this.formGroup?.value?.responsibleSubject,
        },
      );
    }
    subscription.pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(()=>{
      this.bfcNotificationService.showNotification({
        type: BfcNotificationType.SUCCESS,
        message: this.translationService.translate("INSTALLER_MAP.SUCCESS_MESSAGE"),
        options: { duration: this.configurationService.configuration.successNotificationDuration },
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}